import Vue from "vue";
import "babel-polyfill";

import vuetify from "./plugins/vuetify";
import "./plugins/axios";
import "./plugins/notifications";
// import "./plugins/infinite-scroll";
// import "./plugins/debounce";

import "@mdi/font/css/materialdesignicons.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";

import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

export default new Vue({
  router,
  vuetify,
  // notifications,
  render: (h) => h(App),
});
