import Vue from "vue";
import Notifications from "@/api/notifications";

/*
notification.prototype.init = function () {
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBJtsbpT-8dE6r_WPxPGZoPMwjbkEa2Vyc",
    authDomain: "cube-bf3e2.firebaseapp.com",
    databaseURL: "https://cube-bf3e2.firebaseio.com",
    projectId: "cube-bf3e2",
    storageBucket: "cube-bf3e2.appspot.com",
    messagingSenderId: "46491252073",
    appId: "1:46491252073:web:f77139866d3d172ec479f8",
    measurementId: "G-SJMM6RK30W",
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
};

var messaging = firebase.messaging();

notification.prototype.subscribe = function () {
  // запрашиваем разрешение на получение уведомлений
  messaging
    .requestPermission()
    .then(function () {
      // получаем ID устройства
      messaging
        .getToken()
        .then(function (currentToken) {
          console.log(currentToken);

          if (currentToken) {
            sendTokenToServer(currentToken);
          } else {
            console.warn("Не удалось получить токен.");
            setTokenSentToServer(false);
          }
        })
        .catch(function (err) {
          console.warn("При получении токена произошла ошибка.", err);
          setTokenSentToServer(false);
        });
    })
    .catch(function (err) {
      console.warn("Не удалось получить разрешение на показ уведомлений.", err);
    });
};

notification.prototype.sendTokenToServer = function (currentToken) {
  if (!isTokenSentToServer(currentToken)) {
    console.log("Отправка токена на сервер...");

    // var url = ""; // адрес скрипта на сервере который сохраняет ID устройства
    // $.post(url, {
    //   token: currentToken,
    // });

    setTokenSentToServer(currentToken);
  } else {
    console.log("Токен уже отправлен на сервер.");
  }
};

notification.prototype.isTokenSentToServer = function (currentToken) {
  return (
    window.localStorage.getItem("sentFirebaseMessagingToken") == currentToken
  );
};

notification.prototype.setTokenSentToServer = function (currentToken) {
  window.localStorage.setItem(
    "sentFirebaseMessagingToken",
    currentToken ? currentToken : ""
  );
};
*/

function notifications() {
  let self = this;

  self.messaging = null;
  self.initialized = false;

  self.init = function () {
    if (self.initialized) return;

    // Your web app's Firebase configuration
    var firebaseConfig = {
      apiKey: "AIzaSyBJtsbpT-8dE6r_WPxPGZoPMwjbkEa2Vyc",
      authDomain: "cube-bf3e2.firebaseapp.com",
      databaseURL: "https://cube-bf3e2.firebaseio.com",
      projectId: "cube-bf3e2",
      storageBucket: "cube-bf3e2.appspot.com",
      messagingSenderId: "46491252073",
      appId: "1:46491252073:web:f77139866d3d172ec479f8",
      measurementId: "G-SJMM6RK30W",
    };

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();

    self.messaging = firebase.messaging();

    self.initialized = true;
  };

  self.subscribe = function () {
    // запрашиваем разрешение на получение уведомлений
    self.messaging
      .requestPermission()
      .then(function () {
        // получаем ID устройства
        self.messaging
          .getToken()
          .then(function (currentToken) {
            // console.log(currentToken);

            if (currentToken) {
              self.sendTokenToServer(currentToken);
            } else {
              // console.warn("Не удалось получить токен.");
              self.setTokenSentToServer(false);
            }
          })
          .catch(function (err) {
            // console.warn("При получении токена произошла ошибка.", err);
            self.setTokenSentToServer(false);
          });
      })
      .catch(function (err) {
        // console.warn(
        //   "Не удалось получить разрешение на показ уведомлений.",
        //   err
        // );
      });
  };

  self.sendTokenToServer = function (currentToken) {
    if (!self.isTokenSentToServer(currentToken)) {
      // console.log("Отправка токена на сервер...");

      Notifications.push_reg(currentToken, "Browser", firebase.SDK_VERSION);

      self.setTokenSentToServer(currentToken);
    } else {
      // console.log("Токен уже отправлен на сервер.");
    }
  };

  self.isTokenSentToServer = function (currentToken) {
    return (
      window.localStorage.getItem("sentFirebaseMessagingToken") == currentToken
    );
  };

  self.setTokenSentToServer = function (currentToken) {
    window.localStorage.setItem(
      "sentFirebaseMessagingToken",
      currentToken ? currentToken : ""
    );
  };

  self.onMessage = function (callback) {
    self.messaging.onMessage(callback);

    /*
    {
      "from": "46491252073",
      "priority": "normal",
      "notification": {
        "title": "Тест",
        "body": "Сообщение",
        "icon": "https://cube.rubtsovsk.ru/favicon.ico",
        "click_action": "https://cube.rubtsovsk.ru/#/news"
      },
      "collapse_key": "do_not_collapse"
    }
    */
  };
}

const NotificationsPlugin = {
  install: function (Vue, options) {
    Vue.notifications = new notifications();
  },
};

Vue.use(NotificationsPlugin);

export default NotificationsPlugin;

// firebase.initializeApp({
//   messagingSenderId: "46491252073",
// });

// браузер поддерживает уведомления
// вообще, эту проверку должна делать библиотека Firebase, но она этого не делает
/*
if ("Notification" in window) {
  var messaging = firebase.messaging();

  console.log(Notification.permission);

  // пользователь уже разрешил получение уведомлений
  // подписываем на уведомления если ещё не подписали
  if (Notification.permission === "default") {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") subscribe();
    });
  } else if (Notification.permission === "granted") {
    subscribe();
  }

  // по клику, запрашиваем у пользователя разрешение на уведомления
  // и подписываем его
  // $("#subscribe").on("click", function () {
  //   subscribe();
  // });
}
/*

/*
function init() {
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyBJtsbpT-8dE6r_WPxPGZoPMwjbkEa2Vyc",
    authDomain: "cube-bf3e2.firebaseapp.com",
    databaseURL: "https://cube-bf3e2.firebaseio.com",
    projectId: "cube-bf3e2",
    storageBucket: "cube-bf3e2.appspot.com",
    messagingSenderId: "46491252073",
    appId: "1:46491252073:web:f77139866d3d172ec479f8",
    measurementId: "G-SJMM6RK30W",
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

function subscribe() {
  // запрашиваем разрешение на получение уведомлений
  messaging
    .requestPermission()
    .then(function () {
      // получаем ID устройства
      messaging
        .getToken()
        .then(function (currentToken) {
          console.log(currentToken);

          if (currentToken) {
            sendTokenToServer(currentToken);
          } else {
            console.warn("Не удалось получить токен.");
            setTokenSentToServer(false);
          }
        })
        .catch(function (err) {
          console.warn("При получении токена произошла ошибка.", err);
          setTokenSentToServer(false);
        });
    })
    .catch(function (err) {
      console.warn("Не удалось получить разрешение на показ уведомлений.", err);
    });
}

// отправка ID на сервер
function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer(currentToken)) {
    console.log("Отправка токена на сервер...");

    // var url = ""; // адрес скрипта на сервере который сохраняет ID устройства
    // $.post(url, {
    //   token: currentToken,
    // });

    setTokenSentToServer(currentToken);
  } else {
    console.log("Токен уже отправлен на сервер.");
  }
}

// используем localStorage для отметки того,
// что пользователь уже подписался на уведомления
function isTokenSentToServer(currentToken) {
  return (
    window.localStorage.getItem("sentFirebaseMessagingToken") == currentToken
  );
}

function setTokenSentToServer(currentToken) {
  window.localStorage.setItem(
    "sentFirebaseMessagingToken",
    currentToken ? currentToken : ""
  );
}
*/
