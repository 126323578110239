let Counters = {};

Counters.get_counters = function () {
  let params = {
    controller: "counters",
    task: "get_counters",
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data;
    }

    return [];
  });
};

Counters.set_counters = function (counters) {
  let params = {
    controller: "counters",
    task: "set_counters",
    counters,
  };

  return axios.post("/api", params).then(function (response) {
    return response.data;
  });
};

export default Counters;
