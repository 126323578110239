import { useStore } from "@/store";

let Account = {};

Account.isAuthorised = function () {
  let params = {
    controller: "accounts",
    task: "isauthorised",
  };

  return axios.get("/api", { params }).then(function (response) {
    return response.data.authorised;
  });
};

Account.login = function (login, password) {
  let params = {
    controller: "accounts",
    task: "login",
    login: login.trim(),
    password: password.trim(),
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.login_nmbr) {
      const store = useStore();
      store.commit("SET_AUTH", true);
      return true;
    }

    return false;
  });
};

Account.logout = function () {
  let params = {
    controller: "accounts",
    task: "logout",
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      const store = useStore();
      store.commit("SET_AUTH", false);
      return true;
    }

    return false;
  });
};

Account.get_user_data = function () {
  let params = {
    controller: "accounts",
    task: "get_user_data",
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.login_nmbr) {
      return response.data;
    }

    return null;
  });
};

Account.get_address_data = function () {
  let params = {
    controller: "accounts",
    task: "get_address_data",
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.login_nmbr) {
      return response.data;
    }

    return null;
  });
};

Account.get_news_list = function (start, limit) {
  let params = {
    controller: "accounts",
    task: "get_news_list",
    start,
    limit,
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data.news_list;
    }

    return null;
  });
};

Account.get_org_list = function () {
  let params = {
    controller: "accounts",
    task: "get_org_list",
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data.org_list;
    }
  });
};

Account.register = function (reg_data) {
  let params = {
    controller: "accounts",
    task: "org_create_owner_login",
  };

  Object.assign(params, reg_data);

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data;
    }
  });
};

Account.verify = function (uid, guid, changeEmail = false) {
  let params = {
    controller: "accounts",
    task: "verify_login",
    uid: uid,
    guid_v: guid,
  };

  if (changeEmail) {
    params.change_if_exists = "1";
  }

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data;
    }
  });
};

Account.verify_gis_account = function (inn, gis_account) {
  let params = {
    controller: "accounts",
    task: "org_test_own_data",
    subtask: "verify_gis_account",
    org_inn: inn,
    gis_account: gis_account,
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data;
    }
  });
};

Account.verify_passport = function (inn, gis_account, seria, number) {
  let params = {
    controller: "accounts",
    task: "org_test_own_data",
    subtask: "verify_passport",
    org_inn: inn,
    gis_account: gis_account,
    seria: seria,
    number: number,
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data;
    }
  });
};

Account.remind_login = function (email) {
  let params = {
    controller: "accounts",
    task: "remind_login",
    email,
  };

  return axios.get("/api", { params }).then(function (response) {
    if (response.data.status === "Ok") {
      return response.data;
    }
  });
};

Account.delete = function (gis_account) {
  const params = {
    controller: "accounts",
    task: "org_delete_owner_login",
    gis_account,
  };

  return axios.get("/api", { params }).then((response) => {
    if (response.data.status === "Ok") {
      return response.data;
    }
  });
};

export default Account;
