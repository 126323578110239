<template>
  <v-app>
    <component :is="layout"></component>
  </v-app>
</template>

<script setup>
import { computed } from "vue";

const layout = computed(() => {
  // const layout = this.$route.meta.layout || "default";
  const layout = "default";
  return () => import(`@/layouts/${layout}.vue`);
});
</script>

<style>
html {
  overflow: auto;
}
</style>
