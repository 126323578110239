import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Account from "./api/account";
import { useStore } from "@/store";

Vue.use(Router);

let router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/counters",
      name: "counters",
      component: () =>
        import(/* webpackChunkName: "counters" */ "./views/Counters.vue"),
    },
    {
      path: "/invoice",
      name: "invoice",
      component: {
        render(h) {
          return h("transition", [h("keep-alive", [h("router-view")])]);
        },
      },
      children: [
        {
          path: "",
          component: () =>
            import(/* webpackChunkName: "invoice"*/ "./views/Invoice.vue"),
        },
        {
          path: "counters",
          name: "countersList",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "invoice" */ "./components/InvoiceCountersList.vue"
            ),
        },
        {
          path: "servs",
          name: "servsList",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "invoice" */ "./components/InvoiceServsList.vue"
            ),
        },
        {
          path: "recalcs",
          name: "recalcsList",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "invoice" */ "./components/InvoiceRecalcsList.vue"
            ),
        },
        {
          path: "oi",
          name: "oiList",
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "invoice" */ "./components/InvoiceOiList.vue"
            ),
        },
      ],
    },
    {
      path: "/pay",
      name: "pay",
      component: () => import(/* webpackChunkName: "pay" */ "./views/Pay.vue"),
    },
    {
      path: "/call_support",
      name: "call_support",
      beforeEnter(to, from, next) {
        const store = useStore();
        // window.open(store.state.account.address.org_info.url_call_button);
        let url = new URL(store.state.account.address.org_info.url_call_button);
        rutelShowCallPanel(url.searchParams.get("call_id"));
      },
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/Login.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      component: function () {
        Account.logout();
        window.location.href = "/#/";
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () =>
        import(
          /* webpackChunkName: "notifications" */ "./views/Notifications.vue"
        ),
    },
    {
      path: "/news",
      name: "news",
      component: () =>
        import(/* webpackChunkName: "news" */ "./views/News.vue"),
    },
    {
      path: "/register",
      name: "register",
      component: () =>
        import(/* webpackChunkName: "register" */ "./views/Registration.vue"),
    },
    {
      path: "/verify",
      name: "verify",
      component: () =>
        import(
          /* webpackChunkName: "verification" */ "./views/Verification.vue"
        ),
    },
    {
      path: "/play_market",
      name: "play_market",
      beforeEnter(to, from, next) {
        window.open(
          "https://play.google.com/store/apps/details?id=ru.rubtsovsk.cube_android",
          "_blank"
        );
      },
    },
    {
      path: "/apple_store",
      name: "apple_store",
      beforeEnter(to, from, next) {
        window.open(
          "https://apps.apple.com/ru/app/куб-рубцовск/id1515238126",
          "_blank"
        );
      },
    },
    {
      path: "/rustore",
      name: "rustore",
      beforeEnter(to, from, next) {
        window.open(
          "https://www.rustore.ru/catalog/app/ru.rubtsovsk.cube_android",
          "_blank"
        );
      },
    },
    {
      path: "/remind",
      name: "remind",
      component: () =>
        import(/* webpackChunkName: "remind" */ "./views/RemindPassword.vue"),
    },
    {
      path: "/change_email",
      name: "change_email",
      component: () =>
        import(
          /* webpackChunkName: "change_email" */ "./views/ChangeEmail.vue"
        ),
    },
    {
      path: "/demo",
      name: "demo",
      component: () => import(/* webpackChunkName: "wip" */ "./views/Demo.vue"),
    },
    {
      path: "/private",
      name: "private",
      props: { id: "private" },
      component: () =>
        import(/* webpackChunkName: "docs" */ "./components/MdDoc.vue"),
    },
    {
      path: "/profile",
      name: "profile",
      component: () =>
        import(/* webpackChunkName: "docs" */ "./views/Profile.vue"),
    },
    // {
    //   path: "/admin",
    //   name: "admin",
    //   meta: {
    //     layout: "admin",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "admin" */ "./views/admin/admin.vue"),
    //   // component: {
    //   //   render(h) {
    //   //     return h("transition", [h("keep-alive", [h("router-view")])]);
    //   //   },
    //   // },
    //   children: [
    //     {
    //       path: "flats",
    //       meta: {
    //         layout: "admin",
    //       },
    //       component: () =>
    //         import(/* webpackChunkName: "admin" */ "./views/admin/flats.vue"),
    //     },
    //   ],
    // },
  ],
});

export default router;
