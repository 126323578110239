import { useStore } from "@/store";

const store = useStore();

let Notifications = {};

Notifications.save_push_params = function (
  sendPush,
  sendEmail,
  timeStart,
  timeStop
) {
  let params = {
    controller: "accounts",
    task: "set_push_params",
    ena_push_news: sendPush ? "1" : "0",
    ena_mail_news: sendEmail ? "1" : "0",
    push_beg_hour: timeStart,
    push_end_hour: timeStop,
  };

  return axios.get(store.state.api, { params }).then(function (response) {
    return response.data;
  });
};

Notifications.push_reg = function (token, user_agent, version) {
  let params = {
    controller: "accounts",
    task: "push_reg",
    token: token,
    userag: user_agent,
    version: version,
  };

  return axios.get(store.state.api, { params }).then(function (response) {
    return response.data;
  });
};

export default Notifications;
