import Vue from "vue";
import Vuex from "vuex";
import Account from "./api/account";
import Counters from "./api/counters";
import router from "./router";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    api: "/api",
    loading: false,
    authorised: false,
    account: { user: {}, address: { org_info: {} }, counters: [] },
  },
  getters: {
    fio(state) {
      let user = state.account.user;
      return [user.fam, user.name, user.otc].join(" ");
    },

    address(state) {
      let address = state.account.address;
      const items = [
        address.vidstreet,
        address.streetname,
        address.house,
        "-",
        address.flat,
      ];

      return items.filter(Boolean).join(" ");
    },
    authorised(state) {
      return state.authorised;
    },
  },
  mutations: {
    SET_AUTH: function (state, payload) {
      state.authorised = payload;
      if (!state.authorised) {
        state.account = {
          user: {},
          address: {
            org_info: {},
          },
          counters: {},
        };
      }
    },

    SET_LOADING: function (state, payload) {
      state.loading = payload;
    },

    SET_ACC_USER: function (state, payload) {
      state.account.user = payload;
    },

    SET_ACC_ADDR: function (state, payload) {
      state.account.address = payload;
    },

    SET_COUNTERS: function (state, payload) {
      state.account.counters = payload;
    },
  },
  actions: {
    GET_AUTH: async function (context) {
      let authorised = await Account.isAuthorised();
      context.commit("SET_AUTH", authorised);
      if (authorised) {
        context.dispatch("GET_ACC_DATA");
      }
    },

    GET_ACC_DATA: async function (context) {
      let p_user_data = Account.get_user_data();
      let p_addr_data = Account.get_address_data();

      context.commit("SET_ACC_USER", await p_user_data);
      context.commit("SET_ACC_ADDR", await p_addr_data);
    },

    GET_COUNTERS: async function (context) {
      let counters = await Counters.get_counters();
      context.commit("SET_COUNTERS", counters);
      return counters;
    },

    LOADING: async function (context, promise) {
      context.commit("SET_LOADING", true);
      await promise;
      context.commit("SET_LOADING", false);
    },
  },
});

export const useStore = () => store;
