var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ma-3",class:{ 'elevation-2': !_setup.xs },style:([
    _setup.xs
      ? { 'font-size': 'smaller' }
      : {
          'font-size': 'larger',
          margin: '3% 20% !important',
          padding: '2%',
        },
  ]),attrs:{"justify":"center"}},[_c('div',{staticClass:"pa-3"},[_vm._v("Сервис КУБ - личный кабинет жильца.")]),_c('div',{staticClass:"pa-3"},[_vm._v("Вы можете:")]),_c('v-list',{attrs:{"dense":_setup.xs}},_vm._l((_setup.features),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1),_c('v-spacer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }