<template>
  <div
    class="ma-3"
    :style="[
      xs
        ? { 'font-size': 'smaller' }
        : {
            'font-size': 'larger',
            margin: '3% 20% !important',
            padding: '2%',
          },
    ]"
    :class="{ 'elevation-2': !xs }"
    justify="center"
  >
    <div class="pa-3">Сервис КУБ - личный кабинет жильца.</div>
    <div class="pa-3">Вы можете:</div>
    <v-list :dense="xs">
      <v-list-item v-for="item in features" :key="item.id">
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ item.text }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from "vue";

const app = getCurrentInstance();

const features = [
  {
    text: "Передать показания счётчиков",
    icon: "mdi-gauge",
  },
  {
    text: "Читать объявления вашей управляющей компании",
    icon: "mdi-rss",
  },
  {
    text: "Просмотреть ваши квитанции",
    icon: "mdi-text-box-outline",
  },
  {
    text: "Контролировать расходы на общее имущество",
    icon: "mdi-home-outline",
  },
  // {
  //   text: "Оплатить квитанцию через Госуслуги",
  //   icon: "mdi-cash",
  // },
  {
    text: "Получить информацию об управляющей компании",
    icon: "mdi-information-outline",
  },
  {
    text: "Сделать бесплатный WEB-звонок в управляющую компанию",
    icon: "mdi-phone",
  },
  {
    text: "Зарегистрироваться в сервисе не выходя из дома",
    icon: "mdi-account-plus-outline",
  },
];

const xs = computed(() => app.proxy.$vuetify.breakpoint.xs);
</script>
